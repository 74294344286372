/* You can add global styles to this file, and also import other style files */
@import "~@angular/cdk/overlay-prebuilt.css";

body {
    min-width: 1366px;
    overflow-x: auto;
}

.body__content {
    font-size: 16px;
    line-height: 24px;
}

h2 {
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    margin: 12px 0;
    color: #333333;
}

.framed-panel {
    display: flex;
    align-items: center;
    padding: 15px 20px;
    border: 1px solid #e5e5e5;
    border-radius: 2px;
    font-size: 16px;
    line-height: 20px;
}

p .lg-icon--inline {
    vertical-align: bottom;
}

.plm-tool-icon {
    background: transparent url("./assets/svg-icons/toolIcon-plm.svg") no-repeat center center;
    background-size: 36px;
    margin: 8px auto;
}

.breadcrumb-row {
    > lg-filterset-row > lg-filterset-preview > lg-filterset-preview-item {
        display: none;
    }
}

.table__column--with-icon {
    display: flex;
    align-items: center;
}

/* temporary solution until PLM-140 */
.scores__chart {
    .lg-panel__header {
        height: auto;
    }
}
